@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.App, .App.row {
  height: 100vh;
  background-color: #bcbcbc ;
  padding: 8% 10%;
  box-sizing: border-box;
}

.sl-spinner3 {
  margin: 0 auto;
  height: 100vh;
}
.loader_wrapper {
  position: absolute;
  left: calc(50vw - 157px);
  top: calc(50vh - 70px);
  display: flex;
  vertical-align: middle;
  width: 315px;
  height: 160px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.125);
  box-shadow: 2px 3px 5px rgb(202, 202, 202, 0.15);
  background-color: #f7f7f7;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  gap: 5px;
  letter-spacing: 0.7px;
}
.loader_wrapper > div {
  margin: 0 auto;
}
.loader_wrapper .card-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 30px;;
}
.loader_wrapper .mb-3 {
    margin-bottom: 1rem;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 78px;
	height: 60px;
}
.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #000;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.7s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.7s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.7s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.7s infinite;
}
.iframeWrap {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	margin: 0;
    border: 0;
}
.if_wrap {
	position: relative;
}
.ifr_opacity {
	opacity: 0;
}
.outerLoadIframe, .warnIframe {
	margin: 298px 0 0 -2px;
	top: 0;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
/* .warning_popup {
	background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
} */
.redirect_message {
	font-size: 18px;
	font-family: "Montserrat", "arial", "Sans-Serif";
	margin: 0;
}
.warning_box {
    /* width: 100%;
    max-width: 284px;
    background: #f7f7f7;
    border-radius: 0.5rem; */
    padding: 1rem;
    /* margin: 50vh auto 0;
    position: relative;
    top: -178px;
    z-index: 2;
    height: 129px;
    left: 12px;
    display: flex;
    align-items: center;
	flex-direction: column;
	justify-content: space-around; */
	box-sizing: border-box;
}
.skip_btn {
    line-height: 22px;
    font-size: 17px;
    font-weight: 600;
    padding: 0;
    height: 40px;
	border-radius: 0.3rem;
    border: solid 1px #000;
    cursor: pointer;
	color: #fff;
    background-color: #212529;
	display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	width: 90px;
	margin-top: 2px;
}